html,
body {
  margin: 0;
  /* background:#fafafa; */
  z-index:1;
  position:relative;
}



.card {
  position: relative;
  width: 65% !important;
  background: #fcfcfc;
  /* margin: 20px 40px; */
  transition: 0.4s all;
  /* Remove the z-index property from the .card class */
}


.card.open {
  
  background: #ffffff;
}

.card-icon {
    position: absolute;
    top: 10px; /* Adjust the top positioning as needed */
    left: 10px; /* Adjust the left positioning as needed */
    z-index: 1; /* Ensure the icon is above the card content */
  }

@media only screen and (min-width: 600px) {
  .card {
  width:500px;
    margin-top:20px;
    margin-bottom:20px;
    margin-left:auto;
    margin-right:auto;
  }
}

@media only screen and (max-device-width: 800px) and (orientation: portrait) {
  .card {
    margin: 12px 10px;
  }
}

.btn_nav{
  border-color: #A2B0FD !important;
  color: #A2B0FD !important;
}

.btn_nav:hover{
  border:none;
  background-color: #FFBD65 !important;
  color: #fff !important;
}

.showcontent
{
  color: #A2B0FD !important;
}

.showcontent:hover{
  color: #FFBD65 !important;
}

.backgroundElement1{
  position: absolute;
  transform: rotate(1deg);
  top:53%;
  left:8%;
}
.backgroundElement2{
  position: absolute;
  transform: rotate(0deg);
  top:42%;
  left:8%;
}
.backgroundElement3{
  position: absolute;
  transform: rotate(-5deg);
  top:77%;
  left:8%;
}
.backgroundElement4{
  position: absolute;
  transform: rotate(1deg);
  top:53%;
  right: 10%;
 
}
.backgroundElement5{
  position: absolute;
  transform: rotate(.1deg);
  top:42%;
  right:10%;
}
.backgroundElement6{
  position: absolute;
  transform: rotate(.1deg);
  top:31.5% !important;
  left:8%;
}
.backgroundElement7{
  position: absolute;
  transform: rotate(.1deg);
  top:31.5%;
  right:10%;
}
.backgroundElement8{
  position: absolute;
  transform: rotate(1deg);
  top:64.5%;
  left:8%;
}
.backgroundElement9{
  position: absolute;
  transform: rotate(.1deg);
  top:64.5%;
  right:10%;
}
.backgroundElement10{
  position: absolute;
  transform: rotate(.1deg);
  top:77%;
  right:10%;
}
.element{
  position: absolute;
  transform: rotate(.1deg);
  top: 81% ;
  right:50%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

p{
  font-family: 'Libre Baskerville', serif;
  /* font-family: 'Titan One', cursive; */
  font-weight: 300;
  font-size: 16px;
}

h1,h2,h3,h4,h5,h6{
  font-family: 'Fanwood Text', serif;
}

.background1{
  position:absolute;
  left: auto;
  right: 0%;
  overflow: hidden;
  padding-top: 30%;
  width: 30%;
  height: 100%;
  border-radius: 50%;
  background: #a6ddf0;
  filter: blur(170px); 
  z-index: -100;
}
.background2{
  position:absolute;
  bottom: 0%;
  left: auto;
  overflow: hidden;
  padding-top: 10%;
  width: 25%;
  height: 100%;
  border-radius: 50%;
  background: #FFB347;
  filter: blur(170px); 
  z-index: -100;
}

.contact .mail{
  margin-left: -25%;
  overflow: hidden; 
  /* transition: transform 0.3s ease;  */
}

@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

*{font-family: 'Libre Baskerville', serif;
}
.content-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%;
  width: 100%;
   /* Make sure the container takes the full height of the image */
}

.content {
  position: absolute;
  width: 50%;
  padding: 20px; /* Optional: Add padding to the content for better readability */
  color: black; /* Optional: Set text color to white */
  transform: translateY(100px);
  animation: textup 6s ease forwards; /* Use 'forwards' to keep the final state and 'ease' for smoother animation */
}

@keyframes textup {
  0% {
    transform: translateY(100px); /* Start with the initial position */
    opacity: 0; /* Initially, make it transparent */
  }
  100% {
    transform: translateY(0px); /* End at the final position */
    opacity: 1; /* Fully visible */
  }
}


.Aboutcontent{
  position: absolute;
  width: 75%;
  padding: 20px; /* Optional: Add padding to the content for better readability */
  color: black; /* Optional: Set text color to white */
  transform: translateY(100px);
  animation: textup 6s ease forwards; /* Use 'forwards' to keep the final state and 'ease' for smoother animation */
}
/* Add this CSS class to your existing stylesheet */
.image-pickup {
  padding: auto;
  border-radius: 25px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.image-pickup:hover {
  transform: translateY(-10px); /* Adjust the value to control the lift height */
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); /* Add a shadow effect */
}

.Link{
  text-decoration: none;
  color: black;
}

.Style{
  background-image: url("./image/footer.png");
  background-position:"center";
  background-size: cover;
  /* opacity: 0.7; */
}
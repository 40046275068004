.form_data{
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    padding: 15px 10px;
    border-radius: 10px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.form_data h1{
    align-content: center !important;
    text-align: center !important;
    font-size: 30px;
    color: #808080;
}

.form_data p{
    color: #718096;
    font-size: 15px;
    margin-top:9px;
    width: 100%;
}

.form_data form{
    margin-top: 45px;
    width: 100%;
    padding: 10px 50px;
}

.form_data form .form_input input{
    width: 100%;
    padding: 10px;
    border: 1px solid #d4d0d0;
    border-radius: 5px;
    outline: none;    font-size: 14px;
    
}

.form_data form .form_input label{
    font-weight: 600;
    font-size: 15px;
}

.form_input .two{
    display: flex;
    align-items: center;
    position: relative;
}

.form_data form .form_input .login{
    border:1px solid rgba(66, 153, 225,0.6) ;
}

/* .form_data form .form_input .login:focus{
    border:2px solid rgba(31, 233, 31, 0.6);
} */

.form_data form .form_input .valid {
    border: 1px solid rgba(31, 233, 31, 0.6);
  }

.form_data form .form_input .input_error{
border:1px solid rgba(245, 9, 48, 0.6) ;
}



.form_data form .phone select,input[type="tel"]{
    border: 1px solid #d4d0d0;
    outline: none;    font-size: 14px;
    border:1px solid rgba(66, 153, 225,0.6) ;
    padding: 10px;
}

.form_data form .phone input[type="tel"]{
    display: flex;
    width: 82%!important;
    border-radius: 5px;
}

/* .form_data form .phone section,.login:focus{
    border:2px solid rgba(31, 233, 31, 0.6);
    border-radius: 5px;
} */

.form_data form .phone .valid{
    border: 1px solid rgba(31, 233, 31, 0.6);
    border-radius: 5px !important;
}

.form_data form .phone .input_error{
    border:1px solid rgba(230, 54, 83, 0.849) ;
    border-radius: 5px !important;
}

.form_data form .form_input input::placeholder{
    color: #8694a8;
    letter-spacing: .5px;
    font-size: 14px;
}

.form_data form .phone input::placeholder{
    color: #8694a8;
    letter-spacing: .5px;
    font-size: 14px;
}

.form_input .showpass{
    position: absolute;
    right: -1px;
    top: 3.5px;
    padding: 7px 10px;
    margin-right: 5px;
    font-size: 15px;
    font-weight: 600;
    color: #8694a8 ;
    background-color: #EDF2F7;
    border-radius: 5px;
    cursor: pointer;
   
}


form .btn_color{    
    justify-content: center !important;
    width: 50%;
    padding: 10px;    
    border-radius: 5px;
    border-color: #A2B0FD;
    background-color:#A2B0FD;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    margin-top: 10px;
    cursor: pointer;
    transition: all .3s;
}


form .btn_color:hover{
    background-color: #FFBD65;
    color:white;
    border:none;
}

.form_data form p{
    text-align: center;
    margin-top: 20px;
}

.form_data form a{    
    color:#A2B0FD ;
    /* border-bottom: 2px solid #8694a8; */
}

.form_data form a:hover{
    text-decoration: none;
    color:#FFBD65;
}

.btn_nav{
    border-color: #A2B0FD !important;
    color: #7d87be !important;
}

.btn_nav:hover{
    border:none;
    background-color: #FFBD65 !important;
    color: #fff !important;
}

.link {    
    text-shadow: 2px 2px 4px rgba(189, 180, 180, 0.5);
    color:  #7d87be ;
    font-weight: 400 !important;
    text-decoration: none;
    font-size: 19px;    
}

.nav_fixed{
    background-color: #fff !important;
}

.link:hover{
    color: #FFBD65;
}

.background1{
    position:absolute;
    left: auto;
    right: 0%;
    overflow: hidden;
    padding-top: 30%;
    width: 30%;
    height: 100%;
    border-radius: 50%;
    background: #a6ddf0;
    filter: blur(170px); 
   
  }
  .background2{
    position:absolute;
    bottom: 0%;
    left: auto;
    overflow: hidden;
    padding-top: 10%;
    width: 25%;
    height: 100%;
    border-radius: 50%;
    background: #eeec80;
    filter: blur(170px); 
 
  }

